<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <CustomerTypeAdd
      ref="customerTypeAdd"
      :is-sidebar-active.sync="isSidebarActive"
      @add-customer-type="addCustomerType($event)" />
    <CustomerTypeDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-customer-type="initialCustomerType"
      :selected-item.sync="selectedItem"
      @delete-item="openDeleteModal($event)"
      @edit-customer-type="editCustomerType($event)" />
    <CustomerTypeFilter
      :search-code-or-name.sync="filter.searchCodeAndName"
      :search-type-group.sync="filter.searchTypeGroup"
      :search-type-main-group.sync="filter.searchTypeMainGroup"
      :status-filter.sync="filter.status"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Customer Type"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(name)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import CustomerTypeAdd from '@/components/Customers/Type/CustomerTypeAdd.vue'
import CustomerTypeDetail from '@/components/Customers/Type/CustomerTypeDetail.vue'
import CustomerTypeFilter from '@/components/Customers/Type/CustomerTypeFilter.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import TableTop from '@/components/TableTop.vue'
import CustomerTypeProvider from '@/resources/CustomerTypeProvider'

const CustomerTypeService = new CustomerTypeProvider()

export default {
  components: {
    CustomerTypeAdd,
    CustomerTypeDetail,
    CustomerTypeFilter,
    DeleteModal,
    TableTop
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      statusFilter: null,
      filter: {
        searchCodeAndName: '',
        searchTypeGroup: '',
        searchTypeMainGroup: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'name', label: 'Customer Type' },
        { key: 'group', label: 'Type Group' },
        { key: 'mainGroup', label: 'Type MainGroup' },
        // { key: 'updatedAt', label: 'Updated At', sortable: true },
        // { key: 'updatedBy', label: 'Updated By' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      initialCustomerType: {},
      selectedItem: {},
      deleteItem: {}
    }
  },
  created () {
    this.getCustomerType()
  },
  methods: {
    async getCustomerType () {
      try {
        const data = await CustomerTypeService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async addCustomerType (val) {
      try {
        await CustomerTypeService.createGroup(val)
        await this.getCustomerType()
        this.resetFormAndToggleSideBar(this.$refs.customerTypeAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Customer Type Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Customer Type has been added'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Customer Type Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Customer Type has been Failed'
          }
        })
      }
    },
    async editCustomerType (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await CustomerTypeService.updateGroup(val.id, payload)
        await this.getCustomerType()
        this.setInitialCustomerTypeAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Customer Type has been updated'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Customer Type has been Failed'
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await CustomerTypeService.deleteGroup(val.ID)
        await this.getCustomerType()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: `👋 Customer Type ${this.deleteItem.Name} has been deleted`
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    rowClick (val) {
      this.selectedItem = { ...val }
      this.initialCustomerType = { ...val }
      this.isSidebarDetailActive = true
    },
    setInitialCustomerTypeAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialCustomerType = { ...val }
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    fetchFilter () {
      this.setPagination(1)
      this.getCustomerType()
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getCustomerType()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
