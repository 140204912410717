<template>
  <b-sidebar
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Customer Type
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="CustomerTypeAddForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="customerType.code"
            name="customerTypeCode"
            label="Customer Code"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="customerType.name"
            name="customerTypeName"
            label="Customer Type"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="customerType.group"
            name="customerTypeGroup"
            label="Type Group"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="customerType.mainGroup"
            name="customerTypeMainGroup"
            label="Type Main Group"
            :rules="{ required: true }"
            trim />
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit">
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: { TextFieldInput },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      initialCustomerType: {
        code: '',
        name: '',
        group: '',
        mainGroup: ''
      },
      customerType: {
        code: '',
        name: '',
        group: '',
        mainGroup: ''
      }
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      this.$emit('add-customer-type', this.customerType)
      console.log('submit')
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'Create Successfully',
      //     icon: 'EditIcon',
      //     variant: 'success',
      //     text: `👋 Customer Type ${this.customerType.customerTypeName} has been created`
      //   }
      // })
    },
    resetForm () {
      this.customerType = { ...this.initialCustomerType }
      this.$refs.CustomerTypeAddForm.reset()
    }
  }
}
</script>

<style>

.btn-submit {
  min-width: 134px;
}
</style>
