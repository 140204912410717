<template>
  <div>
    <b-row class="align-items-end mb-1">
      <b-col
        sm="12"
        md="5"
        class="mb-1 mb-sm-0">
        <SearchTextInput
          v-model="searchCodeOrName"
          label="Search"
          placeholder="Code or Name"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        sm="12"
        md="5"
        class="mb-1 mb-sm-0">
        <StatusDropdown
          :status-filter.sync="statusFilterValue"
          @change-status-filter="fetchFilter()" />
      </b-col>
    </b-row>
    <b-row class="align-items-end">
      <b-col
        sm="12"
        md="5"
        class="mb-1 mb-sm-0">
        <SearchTextInput
          v-model="searchTypeGroup"
          label="Type Group"
          placeholder="Type Group"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        sm="12"
        md="5"
        class="mb-1 mb-sm-0">
        <SearchTextInput
          v-model="searchTypeMainGroup"
          label="Type MainGroup"
          placeholder="Type MainGroup"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        sm="12"
        md="2"
        class="mb-1 mb-sm-0">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="w-100"
          variant="primary"
          @click="fetchFilter()">
          <feather-icon
            icon="SearchIcon"
            class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
          <span class="align-middle">Search</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import StatusDropdown from '@/components/Filter/StatusDropdown.vue'

export default {
  components: {
    SearchTextInput,
    StatusDropdown
  },
  props: {
    search: {
      type: Object,
      default: () => (
        {
          codeOrName: '',
          typeGroup: '',
          typeMainGroup: ''
        }
      )
    },
    statusFilter: {
      type: String,
      default: null
    }
  },
  computed: {
    searchCodeOrName: {
      get () {
        return this.search.codeOrName
      },
      set (val) {
        this.$emit('update:search-code-or-name', val)
      }
    },
    searchTypeGroup: {
      get () {
        return this.search.typeGroup
      },
      set (val) {
        this.$emit('update:search-type-group', val)
      }
    },
    searchTypeMainGroup: {
      get () {
        return this.search.typeMainGroup
      },
      set (val) {
        this.$emit('update:search-type-main-group', val)
      }
    },
    statusFilterValue: {
      get () {
        return this.statusFilter
      },
      set (val) {
        this.$emit('update:status-filter', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style>

</style>
